$white: #fff;
$black: #000;
$default-primary-base-color: #000;
$default-primary-dark-color: #87b7a2;
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-saira: 'Saira', sans-serif;
$font-standard-size: 62.5%;

// Widths

$page-max-width: 1416px;
$page-max-width-pdp: 1464px;
$page-max-width-cms: 1464px;
$page-max-width-cart: 1464px;
$page-padding-left-mobile: 18px;
$page-padding-right-mobile: 18px;
$page-padding-left: 15px;
$page-padding-right: 15px;

// Colors

$color-neutral-90: #000;
$color-neutral-80: #3C404B;
$color-neutral-70: #797979;
$color-neutral-60: #838D95;
$color-neutral-50: #AAAFB6;
$color-neutral-40: #D0D4D9;
$color-neutral-35: #d9d9d9;
$color-neutral-30: #E0E3E6;
$color-neutral-20: #EDF0F3;
$color-neutral-10: #F9FAFB;
$color-neutral-5: #fff;

$color-primary-90: #00008D;
$color-primary-80: #0908B8;
$color-primary-70: #3718E3;
$color-primary-60: #4B1CF1;
$color-primary-50: #632BFF;
$color-primary-40: #6951FF;
$color-primary-30: #7379FF;
$color-primary-20: #9DA7FF;
$color-primary-10: #C5D2FE;
$color-primary-5: #EBECFD;

$color-secondary-90: #982828;
$color-secondary-80: #ba2828;
$color-secondary-70: #dd2d2d;
$color-secondary-60: #eb4e4e;
$color-secondary-50: #ff5959;
$color-secondary-40: #ff9595;
$color-secondary-30: #ffafaf;
$color-secondary-20: #ffc3c3;
$color-secondary-10: #ffd2d2;
$color-secondary-5: #ffdddd;

$color-other-1: #82a2f6;
$color-other-2: #144fe8;
$color-other-3: #f7a912;
$color-other-4: #632BFF;
$color-other-5: #0908B8;
$color-other-6: #f9f9f9;
$color-other-7: #f3f3f3;
$color-other-8: #a1a1a1;
$color-other-9: #E7E7E7;
$color-other-10: #8A8989;
$color-other-11: #484848;
$color-other-12: #E6E6E6;
$color-other-13: #d1d1d1;
$color-other-14: #f7f7f7;

$white-transparent: rgba(255, 255, 255, 0.8);
$black-transparent: rgba(000, 000, 000, 0.8);
$dark-transparent: rgba(0, 0, 0, 0.6);
