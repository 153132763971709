@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --overlay-background: #0003;
}

.CartOverlay {
    &::before {
        display: none;
    }

    @include desktop {
        width: 460px;
    }

    &-ContentWrapper {
        z-index: 2;
        border: 0;

        .CartOverlay {
            &-CartButton {
                &:hover {
                    background: transparent;
                    border-color: $color-other-4;
                    color: $color-other-4;
                }
            }
        }
    }

    &-Price {
        display: flex;
        gap: 26px;
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: right;
        justify-content: space-between;
        align-items: baseline;

        span {
            font-size: 14px;
        }

        &:nth-child(2) {
            color: #A1A1A1;
        }
    }

    &-Promo {
        border: 2px solid $color-neutral-90;
        background: $color-neutral-90;
        color: $color-neutral-5;
        font-size: 12px;
        font-weight: 500;
        line-height: 13px;
        margin: 24px 24px 0;
        padding-top: 12px;
        padding-bottom: 2px;

        p {
            margin-bottom: 10px;
            font-size: 16px;
            color: $color-neutral-5;
        }
    }

    &-Items {
        padding: 0;
        margin: 0;

        @include scrollbar($color-neutral-90);
    }

    &-Additional {
        background: $color-neutral-20;
    }

    &-Total {
        background: none;
        padding: 24px 18px 16px;

        dt {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
        }

        dd {
            font-size: 20px;
            font-weight: 500;
            line-height: 27px;
        }
    }

    &-TaxInfo {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $color-neutral-50;
        padding-top: 5px;
    }

    &-Actions {
        padding: 0 24px 24px;
        justify-content: space-between;

        .CartOverlay {
            &-CartButton {
                @include desktop {
                    width: 148px;
                }
            }

            &-CheckoutButton {
                @include desktop {
                    max-width: 240px;
                }
            }
        }
    }

    &-Empty {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CartItem-ProductInfo {
        flex-direction: row;
    }

    .CartItem-Wrapper {
        margin-top: 0;

        &_isMobileLayout {
            grid-template-columns: 94px auto;
        }
    }

    .CartItem-Picture img {
        object-position: center;
    }

    .CartItem-Picture_isMobileLayout {
        width: 94px;
        height: 94px;
    }

    &-Total,
    &-Tax,
    &-Discount {
        word-wrap: break-word;
        display: flex;
        justify-content: space-between;
        padding-block-start: 12px;
        padding-block-end: 34px;
        padding-inline: 24px;
        background: var(--cart-overlay-totals-background);

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 7px;
            padding-inline: 14px;
        }

        dd {
            text-align: end;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            white-space: nowrap;

            span {
                display: block;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    &-CheckoutButton {
        svg {
            fill: $color-neutral-5;
        }
    }
}

[dir="ltr"] {
    .CartOverlay {
        right: 0;
    }
}

@include tablet {
    .CartOverlay {
        &-ContentWrapper {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 104px);
        }

        &-Promo {
            flex: 0 0 auto;
        }

        &-Items {
            flex: 1 1 auto;
            max-height: 100%;
            height: 100%;
        }

        &-Additional {
            margin-inline: -20px -20px;
            margin-bottom: -20px;
            flex: 0 0 auto;
        }

        &-Actions {
            .Button.CartOverlay-CartButton {
                width: 40%;
            }
        }
    }
}

@include desktopAndAbove {
    .CartOverlay {
        &-Items {
            padding: 17px;
            margin: 5px 7px 0;
        }
    }
}
