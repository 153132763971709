@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartPage {
    @include page-container;

    max-width: $page-max-width-cart;

    &-Overflow {
        padding: 0;
    }

    &-Heading {
        &_isBasketEmpty {
            text-align: center;
        }

        font-size: 24px;
        font-weight: 400;

        @include mobile {
            margin-bottom: 16px;
        }

        @include desktop {
            font-size: 42px;
            font-weight: 400;
        }

        span {
            padding-left: 8px;

            @include desktop {
                font-size: 30px;
                padding-left: 16px;
            }
        }
    }

    &-Wrapper {
        &_isBasketEmpty {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .CartPage {
            &-ContinueButton {
                border-color: #e5e5e5;
                color: $color-neutral-70;

                &:hover {
                    background: transparent;
                    border-color: $color-other-4;
                    color: $color-other-4;
                }
            }
        }
    }

    &-HeadWrapper {
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        align-items: center;

        @include mobile {
            flex-wrap: wrap;
            gap: 0;
        }

        @include desktop {
            align-items: baseline;
        }
    }

    &-SubHeading {
        font-size: 16px;
        line-height: 24px;
        color: $color-other-8;
        margin-bottom: 0;

        @include mobile {
            width: 100%;
            padding-bottom: 14px;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &-Floating {
        margin-top: 0;
    }

    &-TableHead {
        grid-template-columns: 25% repeat(5, 1fr);
        padding: 12px;
        grid-gap: 12px;
        text-transform: none;

        p {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: $color-neutral-70
        }
    }

    &-ExpandableContentHeading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &-CheckoutButton,
    &-ContinueButton {
        width: 100%;
    }

    &-CheckoutButton {
        margin: 0 0 16px;
    }

    &-PromoBlock {
        border: 1px solid $color-neutral-30;
        padding: 18px 24px;

        >svg {
            flex: 0 0 32px;
        }
    }

    &-PromoText {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $color-neutral-70;
        padding-left: 26px;

        strong {
            color: $color-neutral-90;
            font-weight: 400;
        }
    }

    &-Empty {
        margin-top: 6.5rem;
        text-align: center;
    }

    &-BackShopping {
        margin-top: 6rem;
        margin-bottom: 2rem;

        &:hover {
            color: var(--button-color);
        }
    }

    &-InfoText {
        margin-top: 2rem;
        font-size: 2rem;
    }

    .ProductLinksSlider {
        margin-bottom: 300px;
    }

    .CartItem {
        &-Heading {
            font-size: 14px;
            white-space: pre-wrap;
            margin-bottom: 0;
        }

        .ProductPrice-Price {
            >span {
                font-size: 12px;
            }
        }

        &-Button {
            color: $color-other-8;
        }
    }

    .CheckoutOrderSummary {
        &-SummaryItem {
            &_isTotal {
                padding-top: 0;
                border-top: 0;
            }
        }
    }

    .QuickAddBySku {
        @include mobile {
            margin-inline: -18px;
            margin-bottom: 0;
        }

        &-Title {
            @include mobile {
                flex-wrap: wrap;
                gap: 8px;
            }

            p {
                @include mobile {
                    font-size: 15px;
                    line-height: 21px;
                }
            }
        }

        &-Form {
            @include mobile {
                padding: 24px 18px;
            }

            form.Form {
                @include mobile {
                    grid-template-columns: 1fr 76px;
                }
            }
        }

        button {
            width: 76px;
        }
    }
}

[dir="ltr"] .CartPage-TableHead span {
    text-align: right;

    &:first-of-type {
        text-align: left;
    }
}

@include mobile {
    .CartPage {
        &-Heading {
            display: block;
        }

        &-Items {
            border-top: 1px solid $color-neutral-30;

            @include mobile {
                border-top: 0;
            }
        }

        &-PromoBlock {
            margin: 24px 0 56px;
        }

        &-Discount {
            border: 0;
        }

        .CartItem {
            &-Options {
                color: $color-neutral-90;
            }
        }
    }
}

@include tabletAndAbove {
    .CartPage {
        &-Wrapper {
            display: block;
        }

        &-TableHead {
            background: none;
            grid-template-columns: 1fr 94px 88px 88px;
            padding: 0 0 12px;
            grid-gap: 16px;
            margin-bottom: 0;

            >span:first-of-type {
                padding-left: 10px;
            }

            >span:last-of-type {
                padding-right: 10px;
            }
        }
    }
}

@include desktopAndAbove {
    .CartPage {
        &-TableHead {
            background: none;
            padding: 0 0 16px;
            grid-template-columns: 25% repeat(5, 1fr);
            grid-gap: 20px;

            >span:first-of-type {
                padding-left: 10px;
            }

            >span:last-of-type {
                padding-right: 10px;
            }
        }
    }

    [dir="ltr"] .CartPage-TableHead span {
        text-align: center;

        &:nth-child(2) {
            text-align: left;
        }

        &:nth-child(3), &:nth-child(5) {
            text-align: center;
        }

        &:first-of-type {
            text-align: left;
        }

        &:last-of-type {
            text-align: left;
        }
    }
}

@include above1200 {
    .CartPage {
        padding-left: 44px;
        padding-right: 44px;
        overflow-x: hidden;

        &-Wrapper {
            display: grid;
            grid-template-columns: 1fr 326px;

            &_isBasketEmpty {
                grid-template-columns: 1fr;
                text-align: center;
            }
        }

        &-TableHead {
            grid-template-columns: 25% repeat(5, 1fr);
            padding: 0 0 12px 0;
            grid-gap: 10px;
            margin: 12px 0;
            margin-bottom: 0;
            border-bottom: 1px solid #e5e5e5;
        }
    }

    [dir="ltr"] .CartPage-TableHead span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #797979;


        &:last-of-type {
            text-align: left;
        }
    }
}
