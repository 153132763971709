@mixin button-disabled {
    cursor: not-allowed;
    color: var(--button-disabled-color);
    background-color: var(--button-disabled-background);
    border: var(--button-disabled-border);
}

@mixin button {
    align-items: center;
    background-color: var(--button-background);
    cursor: pointer;
    color: var(--button-color);
    font-size: 15px;
    justify-content: center;
    height: var(--button-height);
    line-height: calc(var(--button-height) - 2 * var(--button-padding)) ;
    padding-inline: var(--button-padding);
    text-decoration: var(--button-text-decoration);
    transition-duration: .25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    text-align: center;
    display: inline-flex;
    vertical-align: top;
    white-space: nowrap;

    @include mobile {
        user-select: none;
    }

    border: {
        color: var(--button-border);
        style: solid;
        width: var(--button-border-width);
    }

    &:hover {
        text-decoration: var(--button-hover-text-decoration);
        border: {
            color: var(--button-hover-border);
        }
    }

    svg {
        margin: 0 8px;
    }

    &:not([disabled]) {
        &:hover {
            @include desktop {
                background-color: var(--button-hover-background);
                color: var(--button-hover-color);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_variant_secondary {
        background-color: var(--secondary-button-background);
        color: var(--secondary-button-color);
        border: {
            color:  var(--secodnary-button-border);
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--secondary-button-hover-background);
                border: {
                    color: var(--secondary-button-hover-border);
                }
            }
        }
    }

    &_variant_gray {
        background-color: $default-secondary-base-color;
        color: $color-primary-60;
        border: {
            color:  $default-secondary-base-color;
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--button-hover-background);
                color: var(--button-hover-color);
            }
        }
    }

    &_variant_outline {
        background-color: var(--outline-button-background);
        color: var(--outline-button-color);
        border: {
            color:  var(--outline-button-border);
            width: 1px;
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--outline-button-hover-background);
                color: var(--outline-button-hover-color);
                border: {
                    color: var(--outline-button-hover-border);
                }
            }
        }
    }

    &_variant_outlineInverse {
        background-color: var(--outline-inverse-button-background);
        color: var(--outline-inverse-button-color);
        border: {
            color:  var(--outline-inverse-button-border);
            width: 1px;
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--outline-inverse-button-hover-background);
                color: var(--outline-inverse-button-hover-color);
                border: {
                    color: var(--outline-inverse-button-hover-border);
                }
            }
        }

        svg {
            path {
                fill: var(--outline-inverse-button-color);
            }
        }

        &:hover {
            svg {
                path {
                    fill: var(--button-color);
                }
            }
        }
    }

    &_variant_outlineDark {
        background-color: var(--outline-dark-button-background);
        color: var(--outline-dark-button-color);
        border: {
            color:  var(--outline-dark-button-border);
            width: 1px;
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--outline-dark-button-hover-background);
                color: var(--outline-dark-button-hover-color);
                border: {
                    color: var(--outline-dark-button-hover-border);
                }
            }
        }
    }

    &_variant_outlineWhite {
        background-color: var(--outline-white-button-background);
        color: var(--outline-white-button-color);
        border: {
            color:  var(--outline-white-button-border);
            width: 1px;
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--outline-white-button-hover-background);
                color: var(--outline-white-button-hover-color);
                border: {
                    color: var(--outline-white-button-hover-border);
                }
            }
        }
    }

    &_variant_outlineDarkTransparentBg {
        background-color: var(--outline-dark-transparent-bg-button-background);
        color: var(--outline-dark-transparent-bg-button-color);
        border: {
            color:  var(--outline-dark-transparent-bg-button-border);
            width: 1px;
        }

        &:not([disabled]) {
            &:hover {
                background-color: var(--outline-dark-transparent-bg-button-hover-background);
                color: var(--outline-dark-transparent-bg-button-hover-color);
                border: {
                    color: var(--outline-dark-transparent-bg-button-hover-border);
                }
            }
        }
    }

    &_variant_likeLink {
        background-color: transparent;
        color: var(--like-link-button-color);
        border: {
            width: 0;
        }

        &:not([disabled]) {
            &:hover {
                background-color: transparent;
                color: var(--like-link-button-hover-color);
            }
        }
    }

    &_variant_iconOnly {
        padding: 0;
        min-width: var(--button-height);
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;

        &:hover,
        &:focus {
            padding: 0;
        }
    }

    &:disabled svg {
        pointer-events: none;
    }

    &_size_medium {
        height: var(--medium-button-height);
        line-height: var(--medium-button-height);
    }

    &_size_small {
        height: var(--small-button-height);
        line-height: var(--small-button-height);
        font-size: 13px;
    }
}
