@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartItem {
    border-color: $color-neutral-30;

    &-Title {
        width: auto;
        margin: 0;
        padding: 0;
        padding-left: 0
    }

    &-Price {
        display: flex;
        flex-direction: column;
        grid-area: 10px;
    }

    &-Options {
        color: $color-neutral-60;
    }

    &-ProductActions {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &-ProductInfo {
        flex-direction: column;
    }

    .ProductPrice {
        margin: 0;

        &-Price {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            gap: 17px;

            span {
                font-size: 12px;
            }
        }

        &-Price:nth-child(2) {
            color: $color-other-10;
            margin-top: 4px;
        }
    }

    &-ItemDiscount {
        text-align: center;
        justify-self: center;
    }

    &-QuantityWrapper {
        justify-self: center;

        &_isCartOverlay {
            .CartItem {
                &-Qty {
                    input {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &-DiscountOff {
        width: fit-content;
        background-color: $color-primary-80;
        padding: 2px 6px;
        color: white;
    }

    &-Actions {
        display: flex;
        align-content: center;
        justify-content: flex-end;
        padding: 10px 12px 30px 12px;
        align-items: center;

        button {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            gap: 5px;
        }
    }

    &-Button {
        a {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            gap: 5px;
            margin-right: 50px;
        }
    }

    &-Heading,
    &-SubHeading {
        font-size: 12px;
    }

    &-Wrapper {
        display: grid;
        grid-gap: 10px;
        padding: 0;
        background: var(--color-white);
        align-items: start;
        grid-template-columns: 25% repeat(5, 1fr);
        margin-bottom: 7px;
        margin-top: 32px;

        &_isCart {
            @include mobile {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                grid-gap: 16px;
                margin-top: 24px;
                margin-bottom: 32px;
            }

            & .CartItem-Title {
                @include desktop {
                    padding-inline-start: 0;
                }
            }

            .CartItem {
                &-ItemDiscount {
                    @include mobile {
                        width: 100%;
                        text-align: left;
                    }
                }

                &-ItemPrice {
                    @include mobile {
                        width: 100%;
                    }

                    .ProductPrice {
                        @include mobile {
                            text-align: left;
                            align-items: flex-start;
                        }
                    }
                }

                &-Price {
                    @include mobile {
                        width: 100%;
                        align-items: flex-start;
                    }

                    .ProductPrice {
                        &-Price {
                            >span {
                                @include mobile {
                                    min-width: 36px;
                                }
                            }
                        }
                    }
                }

                &-QuantityWrapper {
                    @include mobile {
                        width: 100%;
                    }
                }

                &-Qty {
                    input {
                        @include mobile {
                            font-size: 14px;
                            height: 40px;
                        }
                    }

                    button {
                        @include mobile {
                            height: 40px;
                        }
                    }
                }
            }
        }

        &_isMobileLayout {
            grid-template-columns: 64px auto;
            grid-gap: 14px;
            padding: 14px 0;
            min-height: 130px;
        }
    }

    &-Picture {
        img {
            object-position: center;
        }
    }

    &_isCartOverlay {
        .CartItem {
            &-Heading {
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }

    &-ProductTitle {
        @include mobile {
            width: 100%;
        }
    }

    &-ProductImage {
        .Image {
            @include mobile {
                width: 100px;
                height: 66px;
            }
        }
    }

    &-ItemSubWrapper {
        @include mobile {
            width: 100%;
            display: block;
        }

        >div {
            @include mobile {
                display: grid;
                grid-template-columns: 108px 1fr;
                gap: 24px;
                margin-bottom: 14px;
                align-items: center;
            }

            p,
            span,
            .CartItem-Heading {
                @include mobile {
                    font-size: 12px;
                    margin: 0;
                }
            }

            .CartItem {
                &-DiscountOff {
                    @include mobile {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    &-DescriptionName {
        @include mobile {
            margin: 0;
            font-size: 12px;
            line-height: 18px;
            color: $color-neutral-70;
        }
    }
}

a.CartItem-Button {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 50px;
}
